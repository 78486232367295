export const API_USER_SIGN_IN = '/api/auth/login';
export const API_USER_SIGN_UP = '/api/auth/create';
export const API_CAMPAIGN_DATA = '/api/events/:gameId/:rewardpool/:audienceId/:subscribeId';
export const API_GET_CAMPAIGN_DATA = '/api/events';
export const API_GET_REWARD_DATA = 'api/rewards/rewardsinfo';
export const API_REWARD_DATA = '/api/rewards';
export const API_REWARD_BYID = '/api/rewards/:id';
export const API_GAME_DATA = '/api/games';
export const API_EVENT_INFO_ITEM = '/api/fan/event/:id';
export const API_AUDIENCE_DATA = '/api/audiences';
export const API_USERS_DATA = '/api/fan';
export const API_SEND_MAIL = '/api/mail/sendmail';
export const API_RESETPASSWORD = '/api/mail/resetpassword';
export const API_UPDATE_USER_INFO = '/api/users/update';
export const API_UPDATE_BILL_INFO = '/api/users/bill';
export const API_UPDATE_PASS = '/api/auth/updatePass';
export const API_PAYMENT = '/api/payments';
export const API_CREATE_CHECKOUT_SESSION = '/api/payments/create-checkout-session';
export const API_CHECKOUT_SESSION = '/api/payments/checkout-session';
export const API_ADD_TRIVIA = '/api/addtrivia';
export const API_ADD_PRIZE_POOL = '/api/prizepools';
export const API_GET_USER_INFO = '/api/users';
export const API_GET_SUBSCRIPTIONS = '/api/subscriptions';
